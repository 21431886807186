<template>
  <div class="cwp-sharer">
    Share
    <cwp-link
      class="cwp-sharer__item"
      target="_blank"
      :href="`https://www.facebook.com/sharer/sharer.php?u=${encodedUri}`"
      ><cwp-icon interactive name="facebook.svg" alt="Share on Facebook"
    /></cwp-link>
    <cwp-link class="cwp-sharer__item" target="_blank" :href="`https://twitter.com/intent/tweet?url=${encodedUri}`"
      ><cwp-icon interactive name="twitter.svg" alt="Share on Twitter"
    /></cwp-link>
    <cwp-link
      class="cwp-sharer__item"
      target="_blank"
      :href="`https://www.linkedin.com/sharing/share-offsite/?url=${encodedUri}`"
      ><cwp-icon interactive name="linkedin.svg" alt="Share on LinkedIn"
    /></cwp-link>
  </div>
</template>

<script>
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
export default {
  name: 'CwpSharer',
  components: {
    CwpLink,
    CwpIcon,
  },
  data() {
    return {
      encodedUri: '',
    }
  },
  mounted() {
    this.encodedUri = encodeURIComponent(window.location.href)
  },
}
</script>

<style lang="scss">
.cwp-sharer {
  &__item {
    padding: 12px;
    color: var(--body-text-hyperlink-color);

    .cwp-icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
