<template>
  <section class="cwp-event-listing">
    <div v-if="container.showUpcomingEvents && upcoming.items.length" class="grid-container padding-y">
      <div class="cwp-event-listing__grid">
        <div class="cwp-event-listing__description">
          <component :is="container.headingStyle" class="cwp-event-listing__heading">Upcoming Events</component>
        </div>
        <cwp-event-card v-for="(card, i) in upcoming.items" :key="i" :card="card" />
        <div class="cwp-event-listing__load-more">
          <transition name="fade">
            <cwp-link
              v-if="upcoming.pageIndex + 3 <= upcoming.numberOfItems"
              :disabled="upcoming.loading"
              button
              class="button secondary"
              tag="button"
              @click="loadMore('upcoming')"
              >Load more</cwp-link
            >
          </transition>
          <cwp-loader :loading="upcoming.loading" />
        </div>
      </div>
    </div>
    <div v-if="container.showPastEvents && past.items.length" class="grid-container padding-y">
      <div class="cwp-event-listing__grid">
        <div class="cwp-event-listing__description">
          <component :is="container.headingStyle" class="cwp-event-listing__heading">Past Events</component>
        </div>
        <cwp-event-card v-for="(card, i) in past.items" :key="i" :card="card" />
        <div class="cwp-event-listing__load-more">
          <transition name="fade">
            <cwp-link
              v-if="past.pageIndex + 3 <= past.numberOfItems"
              :disabled="past.loading"
              button
              class="button secondary"
              tag="button"
              @click="loadMore('past')"
              >Load more</cwp-link
            >
          </transition>
          <cwp-loader :loading="past.loading" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpEventCard from '@/components/CwpEventCard/CwpEventCard.vue'
import CwpLoader from '@/components/CwpLoader/CwpLoader.vue'
import Kentico from '@/api/kentico'
import { mapGetters } from 'vuex'
export default {
  name: 'CwpEventListing',
  components: {
    CwpLink,
    CwpEventCard,
    CwpLoader,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      upcoming: {
        pageIndex: 1,
        items: [],
        numberOfItems: 1,
        loading: true,
      },
      past: {
        pageIndex: 1,
        items: [],
        numberOfItems: 1,
        loading: true,
      },
    }
  },
  computed: {
    ...mapGetters('site', ['componentIndex']),
    index() {
      return this.componentIndex({ className: 'Revium_EventListing', id: this.container.id })
    },
  },
  mounted() {
    if (this.container.showUpcomingEvents) this.getEventItems('upcoming')
    if (this.container.showPastEvents) this.getEventItems('past')
  },
  methods: {
    getEventItems(eventType) {
      this[eventType].loading = true
      Kentico.getPaginatedItems('events', this.$route.path, this[eventType].pageIndex, 3, { eventType })
        .then(response => {
          if (response?.data[this.index]) {
            this[eventType].items.push(...response.data[this.index].items)
            this[eventType].numberOfItems = response.data[this.index].paginationResponse.numberOfItems
          }
        })
        .finally(() => {
          this[eventType].loading = false
        })
    },
    loadMore(eventType) {
      document.activeElement.blur()
      this[eventType].pageIndex += 3
      this.getEventItems(eventType)
    },
  },
}
</script>

<style lang="scss">
.cwp-event-listing {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  &__heading {
    margin: 0;
  }

  &__description {
    color: var(--text-color);
    grid-column: span 12;
  }

  &__load-more {
    margin-top: 16px;
    margin-bottom: 16px;
    grid-column: span 12;
    text-align: center;

    .cwp-link {
      background-color: var(--secondary-color);
    }
  }

  .cell {
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: span 4;
    }
  }
}
</style>
