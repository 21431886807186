<template>
  <div class="padding-y cwp-logo-container">
    <div class="grid-container">
      <rv-carousel
        v-if="container.logoItems.length"
        class="cwp-logo-container__carousel"
        :class="{ 'rv-carousel--hide-drag': hideDrag }"
        :name="`carousel-${container.id}`"
        :navigation="false"
        :scrollbar="true"
        :options="{
          loop: false,
          initialSlide: 0,
          slidesPerView: 2,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: true,
          spaceBetween: 24,
          autoHeight: false,
          scrollbar: {
            el: '.rv-carousel__scrollbar',
            hide: false,
            dragSize: breakpoints.medium ? 240 : 64,
            draggable: true,
          },
          breakpoints: {
            640: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          },
        }"
      >
        <div v-for="(slide, i) in container.logoItems" :key="i" :class="`rv-carousel__slide rv-carousel__slide--${i}`">
          <a :href="slide.imageUrl" :title="slide.imageAltText" target="_blank"
            ><img class="cwp-logo-container__image" :src="slide.image" :alt="slide.imageAltText"
          /></a>
        </div>
      </rv-carousel>
    </div>
  </div>
</template>

<script>
import { RvCarousel } from '@revium/components'
import { mapState } from 'vuex'
export default {
  name: 'CwpLogoContainer',
  components: {
    RvCarousel,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      breakpoints: state => state.settings.breakpoints,
    }),
    hideDrag() {
      // Swiper has no option to show the scrollbar when there is no overflow
      // Instead we force the track to display and conditionally hide the drag element
      const slideCount = this.container.logoItems.length
      if (this.breakpoints.large) {
        return slideCount <= 4
      }
      if (this.breakpoints.medium) {
        return slideCount <= 3
      }
      return slideCount <= 2
    },
  },
}
</script>

<style lang="scss">
.cwp-logo-container {
  overflow: hidden;

  &__carousel {
    overflow: visible;
    min-height: 0;
  }

  &__image {
    max-width: 100%;
    width: 120px;
    height: auto;
  }

  .rv-carousel {
    &__slide {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
    }
  }
}
</style>
