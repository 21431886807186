<template>
  <ValidationProvider
    v-slot="{ errors, ariaInput, validate, classes }"
    :rules="{ ...field.validation, date: true }"
    :name="field.name"
    :custom-messages="{
      ...field.messages,
      date: 'Must be valid date: DD/MM/YYYY',
    }"
    :vid="field.name"
    class="cwp-date"
  >
    <div :class="{ ...classes }">
      <div v-if="field.tooltip" class="cwp-date__tooltip">
        <div v-if="field.tooltip.icon" class="cwp-date__tooltip-icon">
          <span :class="`${field.tooltip.icon.name}`"></span>
        </div>
        <div v-if="field.tooltip.content" class="cwp-date__tooltip-content">
          {{ field.tooltip.content }}
        </div>
      </div>
      <label v-if="field.label" :for="field.id" class="rv-form__label">
        {{ field.label }}
        <sup v-if="field.validation ? field.validation.required : ''">*</sup>
      </label>
      <v-date-picker
        v-model="fieldValue"
        :input-props="{
          placeholder,
        }"
        :disabled-dates="disabledDates"
        :first-day-of-week="1"
        :masks="{
          L: 'DD/MM/YYYY',
        }"
        @input="updateFieldValue"
      >
        <template #default="{ updateValue, hidePopover }">
          <cleave
            :id="field.id"
            ref="cleave"
            :value="cleaveFieldValue"
            :raw="false"
            :options="{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }"
            :placeholder="placeholder"
            :name="field.name"
            v-bind="ariaInput"
            maxlength="10"
            type="tel"
            class="rv-form__field"
            @keyup.native="ev => handleUpdate(ev, updateValue, validate, hidePopover)"
            @change.native="ev => handleUpdate(ev, updateValue, validate, hidePopover, true)"
          />
        </template>
      </v-date-picker>
      <slot name="messages" :errors="errors" />
    </div>
  </ValidationProvider>
</template>

<script>
import { parseISO, toDate, parse, isValid, format } from 'date-fns'
export default {
  name: 'CwpDatePicker',
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    ariaDescribedby: {
      type: [String, Boolean],
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    fieldName: {
      type: [String, Number],
      default: '',
    },
    formName: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      fieldValue: '',
      cleaveFieldValue: '',
    }
  },
  computed: {
    complete() {
      return this.cleaveFieldValue && this.cleaveFieldValue.length === 10
    },
    disabledDates() {
      let dates = []
      if (this.field.validation.weekdaysOnly) {
        dates = [{ weekdays: [1, 7] }]
      }
      return dates
    },
  },
  methods: {
    updateFieldValue(value) {
      // we can only parse a date, this is done to prevent errors from cleave to date picker
      this.cleaveFieldValue = format(this.fieldValue, 'dd/MM/yyyy')
      if (isValid(this.fieldValue)) {
        this.$emit('input', this.formName, this.fieldName, format(this.fieldValue, 'yyyy-MM-dd'))
      } else {
        this.$emit('input', this.formName, this.fieldName, '')
      }
    },
    handleUpdate(ev, updateValue, validate, hidePopover, change = false) {
      let value = ev.target.value
      const backspace = ev.keyCode === 8 || ev.keyCode === 46

      // Clear the input if anything other than the last character was deleted
      // This is to stop the year digits moving into the month area, and the month digits moving into the day area.
      if (backspace) {
        const valueIfLastCharDeleted = this.cleaveFieldValue.slice(0, value.length)
        if (value !== valueIfLastCharDeleted) {
          value = ''
        }
      }
      this.cleaveFieldValue = value
      if (!value) {
        this.$emit('input', this.formName, this.fieldName, format(this.fieldValue, 'yyyy-MM-dd'))
      }

      if (this.complete) {
        updateValue(value)
      }
      if (change && !this.complete) {
        this.fieldValue = null
      }
      if (this.complete || change) {
        this.$nextTick(() => {
          validate()
          hidePopover()
        })
      }
    },
  },
}
</script>

<style lang="scss">
.cwp-date {
  .rv-form__field {
    border: solid 1px var(--grey-color);
    border-radius: 2px;
    font-size: 16px;
    padding: 9px 24px 9px 9px;
    margin-bottom: 8px;
    display: block;
    width: 100%;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMzMzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41Ij4KICAgICAgICA8cmVjdCB3aWR0aD0iMTMuMTI1IiBoZWlnaHQ9IjExLjM3NSIgeD0iLjQzOSIgeT0iMi4xODgiIHJ4PSIxLjUiLz4KICAgICAgICA8cGF0aCBkPSJNLjQzOSA1LjY4OEwxMy41NjQgNS42ODhNMy45MzkgMy41TDMuOTM5LjQzOE0xMC4wNjQgMy41TDEwLjA2NC40MzgiLz4KICAgIDwvZz4KPC9zdmc+Cg==');
    background-size: 14px 14px;
    background-position: calc(100% - 6px);
    background-repeat: no-repeat;
    background-color: var(--white-color);
    color: var(--dark-grey-color);
  }
  .rv-form__label {
    display: block;
    font-weight: var(--strong-text-font-weight);
    padding-bottom: 8px;
  }
}
</style>
