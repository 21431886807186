<template>
  <section class="cwp-horizontal-card-container">
    <div class="grid-container padding-y">
      <div class="cwp-horizontal-card-container__grid">
        <div v-if="container.heading || container.descriptionText" class="cwp-horizontal-card-container__description">
          <h3 v-if="container.heading" class="cwp-horizontal-card-container__heading">{{ container.heading }}</h3>
          <p v-if="container.descriptionText" class="cwp-horizontal-card-container__description-text">
            {{ container.descriptionText }}
          </p>
        </div>
        <div v-for="(card, i) in cards" :key="i" class="cell">
          <rv-card :card="card" class="rv-card--horizontal" :class="utilityClassGenerator('rv-card', card)">
            <template #figure>
              <figure class="rv-card__figure">
                <div
                  v-if="card.mediaType === 'video' && card.videoEmbedCode"
                  :class="{
                    'rv-card__video': true,
                    'rv-card__video--ready': card.ready,
                  }"
                  :data-video-index="card.index"
                  v-html="youtubeEmbed(card.videoEmbedCode)"
                />
                <img
                  v-else-if="card.imageUrl"
                  v-lazy="$revium.imageHandler(card.imageUrl, { width: 570 })"
                  :alt="card.imageAltText"
                  class="rv-card__image"
                />
                <figcaption v-if="card.imageAltText" class="rv-card__caption">
                  {{ card.imageAltText }}
                </figcaption>
              </figure>
            </template>
            <template #content>
              <div v-if="card.title" class="rv-card__title" v-html="card.title" />
              <div v-if="card.content" class="rv-card__content" v-html="card.content" />
              <div v-if="card.title" class="rv-card__cta">
                <cwp-link
                  v-if="card.buttonText && card.buttonUrl"
                  :to="card.buttonUrl"
                  :target="card.cardButtonOpenNewTab ? '_blank' : ''"
                  button
                  class="rv-card__button"
                >
                  {{ card.buttonText }}
                </cwp-link>
              </div>
            </template>
          </rv-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { RvCard } from '@revium/components'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import { utilityClassGenerator } from '@/functions/utils.js'
export default {
  name: 'CwpHorizontalCardContainer',
  components: {
    CwpLink,
    RvCard,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cards: [],
      videos: [],
    }
  },
  computed: {
    youtubeApi() {
      return this.$store.state.site.youtubeApi
    },
  },
  mounted() {
    // Add extra props for video
    this.cards = this.container.cards.map((card, index) => ({
      ...card,
      playing: false,
      iframe: null,
      player: null,
      ready: false,
      index,
    }))

    this.videos = this.cards.filter(card => card.mediaType === 'video' && card.videoEmbedCode)

    if (this.videos.length) {
      //Initialize youtube array
      if (!window.youTubeIframes) {
        window.youTubeIframes = []
      }

      // Callback for intiators and array to store if api has not yet loaded
      window.onYouTubeIframeAPIReady = () => {
        window.youTubeIframes.forEach(iframe => {
          iframe()
        })
      }

      // Flag the youtube api script to be included
      if (!this.youtubeApi) {
        this.$store.dispatch('site/updateSetting', { youtubeApi: true })
      }

      // If api has loaded intiate iframes, otherwise add to queue
      if (window.YT && window.YT.loaded) {
        this.addListeners()
      } else {
        window.youTubeIframes.push(this.addListeners)
      }
    }
  },
  methods: {
    utilityClassGenerator,
    addListeners() {
      this.$nextTick(() => {
        this.videos.forEach(card => {
          const iframe = this.$el.querySelector(`[data-video-index="${card.index}"] iframe`)
          if (iframe) {
            card.iframe = iframe
            card.player = this.initiatePlayer(iframe, card.index)
          }
        })
      })
    },
    youtubeEmbed(embed) {
      return embed.replace(
        /src=(['"])(https?:\/\/[^ >]*?youtu\.?be[^ >]+?)(?=\1)/g,
        `src=$1$2?version=3&enablejsapi=1&rel=0&modestbranding=1&autohide=1&showinfo=0&fs=0&disablekb=1&origin=${window.origin}`
      )
    },
    initiatePlayer(iframe, index) {
      return new YT.Player(iframe, {
        events: {
          onReady: ev => {
            this.cards[index].ready = true
          },
          onError: ev => {
            this.cards[index].ready = false
          },
          onStateChange: ev => {
            this.cards[index].playing = ev.data === YT.PlayerState.PLAYING
          },
        },
      })
    },
  },
}
</script>

<style lang="scss">
.cwp-horizontal-card-container {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  &__description {
    text-align: var(--description-text-alignment);
    color: var(--text-color);
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: 3 / span 8;
    }
  }

  &__heading {
    margin: 0;
    color: currentColor;
  }

  &__description-text {
    margin: 0;
  }

  .cell {
    grid-column: span 12;
  }

  @include media('>=large') {
    &__component-type--full-width .cell {
      grid-column: span 12;
    }

    &__component-type--feature .cell {
      grid-column: 2 / span 10;
    }

    &__component-type--narrow .cell {
      grid-column: 3 / span 8;
    }
  }
}
</style>
