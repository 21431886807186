<template>
  <section class="cwp-event-details">
    <div class="grid-container padding-y">
      <div class="cwp-event-details__grid">
        <div class="cwp-event-details__container">
          <cwp-breadcrumbs v-if="container.displayBreadcrumbs" :container="page" :padding="false" />
          <h1 v-if="container.title" class="cwp-event-details__title">{{ container.title }}</h1>
          <div class="cwp-event-details__meta">
            <div v-if="container.eventDate" class="cwp-event-details__meta-item">
              <cwp-icon name="calendar.svg" alt="Event Date" class="cwp-event-details__meta-icon" />
              <div class="cwp-event-details__meta-text">{{ formatDate(container.eventDate) }}</div>
            </div>
            <div v-if="container.eventLocation" class="cwp-event-details__meta-item">
              <cwp-icon name="marker.svg" alt="Event Location" class="cwp-event-details__meta-icon" />
              <div class="cwp-event-details__meta-text">{{ container.eventLocation }}</div>
            </div>
            <div v-if="container.eventStartTime" class="cwp-event-details__meta-item">
              <cwp-icon name="clock.svg" alt="Event Time" class="cwp-event-details__meta-icon" />
              <div class="cwp-event-details__meta-text">
                {{ formatTime(container.eventStartTime) }}
                <span v-if="container.eventEndTime">&nbsp;- {{ formatTime(container.eventEndTime) }}</span>
              </div>
            </div>
            <div v-if="container.cost" class="cwp-event-details__meta-item">
              <cwp-icon name="money.svg" alt="Event Cost" class="cwp-event-details__meta-icon" />
              <div class="cwp-event-details__meta-text">{{ container.cost }}</div>
            </div>
          </div>
          <div v-if="container.cost" class="cwp-event-details__cta-container">
            <cwp-link
              v-if="container.registrationUrl"
              :href="container.registrationUrl"
              class="cwp-event-details__cta-button button primary"
              target="_blank"
              >Register for this event</cwp-link
            >
            <cwp-link
              v-if="container.latitude && container.longitude"
              :href="`https://www.google.com/maps/dir/?api=1&destination=${container.latitude},${container.longitude}`"
              class="cwp-event-details__cta-button button secondary"
              target="_blank"
              >Get Directions</cwp-link
            >
            <div class="cwp-event-details__sharer">
              <cwp-sharer />
            </div>
          </div>
          <div
            v-if="container.eventDescription"
            class="cwp-event-details__news-content cwp-html-content"
            v-html="container.eventDescription"
          />
          <cwp-map
            v-if="container.latitude && container.longitude"
            title="Event Location Map"
            :latitude="container.latitude"
            :longitude="container.longitude"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CwpBreadcrumbs from '@/components/CwpBreadcrumbs/CwpBreadcrumbs.vue'
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpSharer from '@/components/CwpSharer/CwpSharer.vue'
import CwpMap from '@/components/CwpMap/CwpMap.vue'
import { formatDate, formatTime, responsiveTables, responsiveEmbed } from '@/functions/utils'
import { mapState } from 'vuex'
export default {
  name: 'CwpEventDetails',
  components: {
    CwpBreadcrumbs,
    CwpSharer,
    CwpIcon,
    CwpLink,
    CwpMap,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      overflowControllers: [],
    }
  },
  computed: {
    ...mapState({
      page: state => state.site.page,
    }),
  },
  mounted() {
    this.overflowControllers = responsiveTables(this.$el)
    responsiveEmbed(this.$el)
  },
  destroyed() {
    this.overflowControllers.map(controller => controller.destroy())
  },
  methods: {
    formatDate,
    formatTime,
  },
}
</script>

<style lang="scss">
.cwp-event-details {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  &__container {
    color: var(--text-color);
    grid-column: span 12;

    @include media('>=large') {
      grid-column: 3 / span 8;
    }
  }

  &__heading {
    margin: 0;
  }

  &__meta {
    @include inline-grid(2, auto);

    margin-bottom: 32px;
  }

  &__meta-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-column: span 2;

    @include media('>=medium') {
      grid-column: span 1;
    }
  }

  &__cta-button {
    margin: 0 16px 16px 0;
  }

  &__meta-icon {
    max-width: 24px;
    max-height: 24px;
    margin-right: 16px;
  }

  &__meta-text {
    padding-top: 2px;
  }

  &__cta-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 16px;

    @include media('>=medium') {
      align-items: center;
      flex-direction: row;
      margin-bottom: 8px;
    }
  }

  &__sharer {
    display: flex;
    flex-grow: 1;
    margin-bottom: 8px;
    margin-right: -12px;

    @include media('>=medium') {
      justify-content: flex-end;
    }
  }

  &__cta-button + &__sharer {
    margin-top: 16px;

    @include media('>=medium') {
      margin-top: 0;
    }
  }

  &__image {
    position: absolute;
    object-fit: cover;
    object-position: center;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
  }

  .cwp-map {
    margin: 32px 0;
  }
}
</style>
