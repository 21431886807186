import CwpAccordionContainer from '@/components/CwpAccordionContainer/CwpAccordionContainer.vue'
import CwpBanner from '@/components/CwpBanner/CwpBanner.vue'
import CwpBreadcrumbs from '@/components/CwpBreadcrumbs/CwpBreadcrumbs.vue'
import CwpCarouselContainer from '@/components/CwpCarouselContainer/CwpCarouselContainer.vue'
import CwpCarouselBannerContainer from '@/components/CwpCarouselBannerContainer/CwpCarouselBannerContainer.vue'
import CwpContactAndDisplaySuiteDetails from '@/components/CwpContactAndDisplaySuiteDetails/CwpContactAndDisplaySuiteDetails.vue'
import CwpDocumentContainer from '@/components/CwpDocumentContainer/CwpDocumentContainer.vue'
import CwpEventDetails from '@/components/CwpEventDetails/CwpEventDetails.vue'
import CwpEventListing from '@/components/CwpEventListing/CwpEventListing.vue'
import CwpFeatureContainer from '@/components/CwpFeatureContainer/CwpFeatureContainer.vue'
import CwpGeneralContentBlock from '@/components/CwpGeneralContentBlock/CwpGeneralContentBlock.vue'
import CwpGenericForm from '@/components/CwpGenericForm/CwpGenericForm.vue'
import CwpHorizontalCardContainer from '@/components/CwpHorizontalCardContainer/CwpHorizontalCardContainer.vue'
import CwpHubSpotForm from '@/components/CwpHubSpotForm/CwpHubSpotForm.vue'
import CwpHubSpotNewsletter from '@/components/CwpHubSpotNewsletter/CwpHubSpotNewsletter.vue'
import CwpLatestNews from '@/components/CwpLatestNews/CwpLatestNews.vue'
import CwpLocationDetails from '@/components/CwpLocationDetails/CwpLocationDetails.vue'
import CwpLogoContainer from '@/components/CwpLogoContainer/CwpLogoContainer.vue'
import CwpModalPopup from '@/components/CwpModalPopup/CwpModalPopup.vue'
import CwpNewsletter from '@/components/CwpNewsletter/CwpNewsletter.vue'
import CwpNewsArticle from '@/components/CwpNewsArticle/CwpNewsArticle.vue'
import CwpNewsListing from '@/components/CwpNewsListing/CwpNewsListing.vue'
import CwpRelatedContent from '@/components/CwpRelatedContent/CwpRelatedContent.vue'
import CwpRunwayMaps from '@/components/CwpRunwayMaps/CwpRunwayMaps.vue'
import CwpSearchResults from '@/components/CwpSearchResults/CwpSearchResults.vue'
import CwpSlimBanner from '@/components/CwpSlimBanner/CwpSlimBanner.vue'
import CwpSlimCarouselBannerContainer from '@/components/CwpSlimCarouselBannerContainer/CwpSlimCarouselBannerContainer.vue'
import CwpTabContainer from '@/components/CwpTabContainer/CwpTabContainer.vue'
import CwpVerticalCardContainer from '@/components/CwpVerticalCardContainer/CwpVerticalCardContainer.vue'
import CwpIframe from '@/components/CwpIframe/CwpIframe.vue'
import CwpProjectFinder from '@/components/CwpProjectFinder/CwpProjectFinder.vue'

export default {
  CwpAccordionContainer,
  CwpBanner,
  CwpBreadcrumbs,
  CwpCarouselBannerContainer,
  CwpCarouselContainer,
  CwpContactAndDisplaySuiteDetails,
  CwpDocumentContainer,
  CwpEventDetails,
  CwpEventListing,
  CwpFeatureContainer,
  CwpGeneralContentBlock,
  CwpGenericForm,
  CwpHorizontalCardContainer,
  CwpHubSpotForm,
  CwpHubSpotNewsletter,
  CwpLatestNews,
  CwpLocationDetails,
  CwpLogoContainer,
  CwpModalPopup,
  CwpNewsletter,
  CwpNewsArticle,
  CwpNewsListing,
  CwpRelatedContent,
  CwpRunwayMaps,
  CwpSearchResults,
  CwpSlimBanner,
  CwpSlimCarouselBannerContainer,
  CwpTabContainer,
  CwpVerticalCardContainer,
  CwpIframe,
  CwpProjectFinder,
}
