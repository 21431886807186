<template>
  <section class="cwp-project-detail">
    <div
      class="cwp-project-detail__logo-container"
      :style="{ backgroundImage: `url('${baseUrl}${projectData.image}')` }"
    >
      <div class="cwp-project-detail__logo-container__overlay"></div>
      <img class="cwp-project-detail__logo" :src="`${baseUrl}${projectData.logo}`" :alt="projectData.name" />
    </div>
    <div class="cwp-project-detail__info-container">
      <div class="cwp-project-detail__info-container__child">
        <div v-if="description" class="cwp-project-detail__description" v-html="description"></div>
        <cwp-carousel-container
          v-if="images.length"
          :container="{
            items: images,
          }"
          :centered-slides="false"
          hide-controls
        ></cwp-carousel-container>
      </div>
      <div class="cwp-project-detail__info-container__child">
        <h5 v-if="subHeading" class="cwp-project-detail__sub-heading" v-html="subHeading"></h5>

        <div class="cwp-project-detail__info-item">
          <cwp-project-map :locations="[projectData]" :show-info-windows="false" />
        </div>
        <div class="cwp-project-detail__info-item cwp-project-detail__info-item--align">
          <cwp-icon name="location.svg" alt="Location marker" class="cwp-project-detail__icon" />
          {{ projectData.location }}
        </div>
        <div class="cwp-project-detail__info-item cwp-project-detail__info-item--align">
          <cwp-icon name="building.svg" alt="Location marker" class="cwp-project-detail__icon" />
          {{ projectData.type }}
        </div>
        <div class="cwp-project-detail__info-item">
          <CwpStatusMarker :status="projectData.status" />
        </div>
        <div class="cwp-project-detail__button-container">
          <cwp-link
            v-if="projectData.websiteUrl"
            class="button secondary"
            :href="projectData.websiteUrl"
            target="_blank"
          >
            Visit website
          </cwp-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import parse from 'node-html-parser'

import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpCarouselContainer from '@/components/CwpCarouselContainer/CwpCarouselContainer.vue'

import CwpProjectMap from './CwpProjectMap.vue'
import CwpStatusMarker from './CwpStatusMarker.vue'

export default {
  name: 'CwpProjectDetail',
  components: { CwpIcon, CwpStatusMarker, CwpLink, CwpProjectMap, CwpCarouselContainer },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      subHeading: null,
      description: null,
      images: [],
    }
  },
  created() {
    axios({
      method: 'get',
      url: `${this.baseUrl}${this.projectData.url}`,
    }).then(response => {
      const parsedResult = parse(response.data)
      this.subHeading = parsedResult.querySelector('.banner-inner div')?.toString() ?? null
      const mainPageData = parsedResult.querySelector('.project-content div')
      this.description = mainPageData.querySelector('div')?.toString() ?? null

      mainPageData.querySelectorAll('.fn-carousel img').forEach(element => {
        const imageSrc = element.attributes.src
        if (imageSrc && this.images.indexOf(imageSrc) === -1) {
          this.images.push({ image: `${this.baseUrl}${imageSrc}` })
        }
      }, [])
    })
  },
}
</script>

<style lang="scss">
.cwp-project-detail {
  background-color: #fff;

  &__logo-container {
    position: relative;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    height: 190px;

    &__overlay {
      background-color: #fff;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__logo {
    position: relative;
    border-radius: 5px;
    height: 100%;
  }

  &__info-container {
    display: flex;
    padding: 20px;
    flex-direction: column;

    @include media('>=large') {
      flex-direction: row;

      &__child:first-child {
        width: 60%;
        margin-right: 30px;
      }

      &__child:last-child {
        width: 40%;
      }
    }
  }

  &__description {
    div *:first-child {
      margin-top: 15px;
    }
  }

  &__sub-heading {
    margin-top: 15px;

    p:only-child {
      margin-top: 0;
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .button {
      margin: 0;
      width: 100%;
    }
  }

  &__info-item {
    margin-bottom: 15px;
    font-weight: var(--strong-text-font-weight);
    text-transform: uppercase;

    &--align {
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
</style>
