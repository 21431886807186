<template>
  <div :class="`${padding ? 'grid-container padding-y' : ''}`">
    <div class="cwp-breadcrumbs">
      <cwp-link class="cwp-breadcrumbs__home" :to="`/${projectPath}/`"
        ><cwp-icon name="home.svg" alt="Home"
      /></cwp-link>
      <div class="rv-breadcrumbs__separator">
        <cwp-icon name="angle-right.svg" />
      </div>
      <rv-breadcrumbs :breadcrumbs="breadcrumbs">
        <cwp-icon name="angle-right.svg" />
      </rv-breadcrumbs>
    </div>
  </div>
</template>

<script>
import { RvBreadcrumbs } from '@revium/components'
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import { mapState } from 'vuex'
export default {
  name: 'CwpBreadcrumbs',
  components: {
    CwpIcon,
    CwpLink,
    RvBreadcrumbs,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    padding: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      projectPath: window.location.pathname.split('/')[1],
    }
  },
  computed: {
    ...mapState({
      breakpoints: state => state.settings.breakpoints,
    }),
    breadcrumbs() {
      return this.container.breadcrumbs.map(crumb => ({
        ...crumb,
        text: this.breakpoints.medium ? crumb.text : this.truncateContent(crumb.text, 3, 3, 3),
      }))
    },
  },
  mounted() {
    this.truncateBreadCrumbs()
  },
  methods: {
    truncateBreadCrumbs(array) {
      this.container.breadcrumbs.forEach(crumb =>
        this.breadcrumbs.push({
          ...crumb,
          text: this.truncateContent(crumb.text, 3, 3, 3),
        })
      )
    },
    truncateContent(str, firstCharCount = str.length, endCharCount = 0, dotCount = 3) {
      if (str.length > 10 && window.innerWidth < 640) {
        return `${str.substring(0, firstCharCount)} ${'.'.repeat(dotCount)} ${str.substring(
          str.length - endCharCount,
          str.length
        )}`
      } else {
        return str
      }
    },
  },
}
</script>

<style lang="scss">
.cwp-breadcrumbs {
  display: flex;
  align-items: center;
  .cwp-icon {
    width: 10px;
    height: 10px;
    color: var(--body-text-color);
    display: inline-block;
  }

  &__home {
    .cwp-icon {
      width: 14px;
      height: 14px;
      margin: 4px 4px 0 0;
    }
  }
}
.rv-breadcrumbs {
  &__item:first-child {
    display: none;
  }
  &__link {
    color: var(--body-text-color);
    opacity: 0.63;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      opacity: 1;
    }
  }
  .rv-link--exact-active {
    opacity: 1;
  }
  &__separator {
    padding: 0 4px;
  }
}
</style>
