<template>
  <div class="cwp-general-content-block">
    <div class="grid-container padding-y">
      <div class="cwp-general-content-block__grid">
        <div
          v-for="(content, key) in container.htmlContent"
          :key="key"
          class="cwp-general-content-block__column cell cwp-html-content"
          v-html="content"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { responsiveTables, responsiveEmbed } from '@/functions/utils'
export default {
  name: 'CwpGeneralContentBlock',
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      overflowControllers: [],
    }
  },
  mounted() {
    this.overflowControllers = responsiveTables(this.$el)
    responsiveEmbed(this.$el)
  },
  destroyed() {
    this.overflowControllers.map(controller => controller.destroy())
  },
}
</script>

<style lang="scss">
.cwp-general-content-block {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  .cell {
    grid-column: span 12;
  }

  @include media('>=medium') {
    &__text-width--narrow .cell {
      grid-column: span 8;
    }

    &__text-alignment--center .cell {
      grid-column: 3 / span 8;
    }

    &__text-alignment--right .cell {
      grid-column: 5 / span 8;
    }

    &__block-type--three-columns .cell {
      grid-column: span 4;
    }

    &__block-type--two-columns {
      .cell {
        grid-column: span 6;
      }
      &.cwp-general-content-block__column-width--two-thirds-one-third {
        .cell:first-child {
          grid-column: span 8;
        }
        .cell:last-child {
          grid-column: span 4;
        }
      }
      &.cwp-general-content-block__column-width--one-third-two-thirds {
        .cell:first-child {
          grid-column: span 4;
        }
        .cell:last-child {
          grid-column: span 8;
        }
      }
    }
  }
}
</style>
