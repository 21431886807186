<template>
  <section class="cwp-news-listing">
    <div class="grid-container padding-y">
      <div class="cwp-news-listing__grid">
        <div v-if="container.heading" class="cwp-news-listing__description">
          <component :is="container.headingStyle" v-if="container.displayHeading" class="cwp-news-listing__heading">{{
            container.heading
          }}</component>
        </div>
        <cwp-news-card v-for="(card, i) in items" :key="i" :card="card" />
        <div class="cwp-news-listing__load-more">
          <transition name="fade">
            <cwp-link
              v-if="pageIndex + 9 <= numberOfItems"
              :disabled="loading"
              button
              class="button secondary"
              tag="button"
              @click="loadMore"
              >Load more articles</cwp-link
            >
          </transition>
          <cwp-loader :loading="loading" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpNewsCard from '@/components/CwpNewsCard/CwpNewsCard.vue'
import CwpLoader from '@/components/CwpLoader/CwpLoader.vue'
import Kentico from '@/api/kentico'
import { mapGetters } from 'vuex'
export default {
  name: 'CwpNewsListing',
  components: {
    CwpLink,
    CwpNewsCard,
    CwpLoader,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageIndex: 1,
      items: [],
      numberOfItems: 1,
      loading: true,
    }
  },
  computed: {
    ...mapGetters('site', ['componentIndex']),
    index() {
      return this.componentIndex({ className: 'Revium_NewsListing', id: this.container.id })
    },
  },
  mounted() {
    this.getNewsItems()
  },
  methods: {
    getNewsItems() {
      this.loading = true
      Kentico.getPaginatedItems('news', this.$route.path, this.pageIndex)
        .then(response => {
          if (response?.data[this.index]) {
            this.items.push(...response.data[this.index].items)
            this.numberOfItems = response.data[this.index].paginationResponse.numberOfItems
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadMore() {
      document.activeElement.blur()
      this.pageIndex += 9
      this.getNewsItems()
    },
  },
}
</script>

<style lang="scss">
.cwp-news-listing {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  &__heading {
    margin: 0;
  }

  &__description {
    color: var(--text-color);
    grid-column: span 12;
  }

  &__load-more {
    margin-top: 16px;
    margin-bottom: 16px;
    grid-column: span 12;
    text-align: center;

    .cwp-link {
      background-color: var(--secondary-color);
    }
  }

  .cell {
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: span 4;
    }
  }
}
</style>
