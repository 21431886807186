<template>
  <div class="padding-y cwp-carousel-container cwp-carousel-container">
    <rv-carousel
      v-if="slides.length"
      class="cwp-carousel-container__carousel"
      :name="`carousel-${container.id}`"
      :navigation="slides.length > 1 && !hideControls"
      :scrollbar="true"
      :options="{
        loop: false,
        initialSlide: slides.length > 2 ? 1 : 0,
        observer: true,
        observeParents: true,
        slidesPerView: 'auto',
        centeredSlides: centeredSlides,
        spaceBetween: 24,
        autoHeight: false,
        slidesPerColumnFill: 'row',
        scrollbar: {
          el: '.rv-carousel__scrollbar',
          hide: false,
          dragSize: breakpoints.medium ? 240 : 64,
          draggable: true,
        },
      }"
      :class="{
        'rv-carousel--hide-drag': slides.length === 1,
        'rv-carousel--overlaid': container.textLayout === 'overlaid',
        'rv-carousel--below': container.textLayout !== 'overlaid',
      }"
      @slideChange="stopVideo"
    >
      <div
        v-for="slide in slides"
        :key="slide.index"
        :class="{
          'rv-carousel__slide': true,
          'rv-carousel--text-background': slide.displayOverlay,
          'rv-carousel--video': slide.video,
        }"
      >
        <template v-if="slide.video">
          <div v-if="slide.video">
            <div
              :class="{
                'rv-carousel__video': true,
                'rv-carousel__video--ready': slide.ready,
              }"
            >
              <div :data-video-index="slide.index" v-html="youtubeEmbed(slide.video)" />
              <img v-if="slide.image" :src="slide.image" :alt="slide.imageAltText" class="rv-carousel__video--image" />
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="slide.image" :style="`background-image:url('${slide.image}');`" class="rv-carousel__image" />
        </template>
        <div
          :class="{
            'rv-carousel__details': true,
            'rv-carousel--left': container.textAlignment === 'left',
            'rv-carousel--center': container.textAlignment !== 'left',
            'rv-carousel--hide-details': slide.playing,
            [`rv-carousel__text-color--${slide.textColor}`]: true,
          }"
        >
          <h4 v-if="slide.heading" class="rv-carousel__details-item">{{ slide.heading }}</h4>
          <span v-if="slide.description" class="rv-carousel__details-item" v-html="slide.description"></span>
        </div>
      </div>
      <template #prev>
        <cwp-icon name="angle-left.svg" />
      </template>
      <template #next>
        <cwp-icon name="angle-right.svg" />
      </template>
    </rv-carousel>
  </div>
</template>

<script>
import { RvCarousel } from '@revium/components'
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
export default {
  name: 'CwpCarouselContainer',
  components: {
    CwpIcon,
    RvCarousel,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    centeredSlides: {
      type: Boolean,
      default: true,
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null,
      slides: [],
      videos: [],
    }
  },
  computed: {
    youtubeApi() {
      return this.$store.state.site.youtubeApi
    },
    breakpoints() {
      return this.$store.state.settings.breakpoints
    },
  },
  mounted() {
    // Add extra props for video
    this.slides = this.container.items.map((slide, index) => ({
      ...slide,
      playing: false,
      iframe: null,
      player: null,
      ready: false,
      index,
    }))

    // Create intersection observer to stop video playback
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio != 1) {
            this.stopVideo()
          }
        })
      },
      { threshold: 0.5 }
    )

    this.videos = this.slides.filter(slide => slide.video)

    if (this.videos.length) {
      //Initialize youtube array
      if (!window.youTubeIframes) {
        window.youTubeIframes = []
      }

      // Callback for intiators and array to store if api has not yet loaded
      window.onYouTubeIframeAPIReady = () => {
        window.youTubeIframes.forEach(iframe => {
          iframe()
        })
      }
      // Flag the youtube api script to be included
      if (!this.youtubeApi) {
        this.$store.dispatch('site/updateSetting', { youtubeApi: true })
      }

      // If api has loaded intiate iframes, otherwise add to queue
      if (window.YT && window.YT.loaded) {
        this.addListeners()
      } else {
        window.youTubeIframes.push(this.addListeners)
      }
    }
  },
  destroyed() {
    this.videos.forEach(({ iframe }) => iframe && this.observer.unobserve(iframe))
  },
  methods: {
    addListeners() {
      this.$nextTick(() => {
        this.videos.forEach(slide => {
          const iframe = this.$el.querySelector(`[data-video-index="${slide.index}"] iframe`)
          if (iframe) {
            this.observer.observe(iframe)
            slide.iframe = iframe
            slide.player = this.initiatePlayer(iframe, slide.index)
          }
        })
      })
    },
    youtubeEmbed(embed) {
      return embed.replace(
        /src=(['"])(https?:\/\/[^ >]*?youtu\.?be[^ >]+?)(?=\1)/g,
        `src=$1$2?version=3&enablejsapi=1&rel=0&modestbranding=1&autohide=1&showinfo=0&fs=0&disablekb=1&origin=${window.origin}`
      )
    },
    initiatePlayer(iframe, index) {
      return new YT.Player(iframe, {
        events: {
          onReady: ev => {
            this.slides[index].ready = true
          },
          onError: ev => {
            this.slides[index].ready = false
          },
          onStateChange: ev => {
            this.slides[index].playing = ev.data === YT.PlayerState.PLAYING
          },
        },
      })
    },
    stopVideo() {
      this.videos.forEach(video => {
        if (video?.player?.pauseVideo) video.player.pauseVideo()
      })
    },
  },
}
</script>

<style lang="scss">
.cwp-carousel-container {
  background-color: var(--background-color);
}
</style>
