<script>
export default {
  name: 'CwpComponentMargin',
  props: {
    paddingBottom: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  render: function (createElement) {
    if (this.paddingBottom) {
      return createElement(
        'div',
        {
          attrs: {
            class: `cwp__mb`,
          },
        },
        [this.$scopedSlots.default()]
      )
    } else {
      return this.$scopedSlots.default()
    }
  },
}
</script>
