<template>
  <section>
    <cwp-modal v-model="modal">
      <div class="cwp-html-content" v-html="container.modalContent" />
    </cwp-modal>
  </section>
</template>

<script>
import CwpModal from '@/components/CwpModal/CwpModal.vue'
import { responsiveTables, responsiveEmbed } from '@/functions/utils'
import differenceInDays from 'date-fns/differenceInDays'
export default {
  name: 'CwpModalPopup',
  components: {
    CwpModal,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      overflowControllers: [],
    }
  },
  mounted() {
    const storageKey = `${window.location.pathname.split('/')[1]}-modal-${this.container.id}`
    const date = localStorage.getItem(storageKey)
    if (!date || differenceInDays(new Date(), new Date(date)) >= 7) {
      setTimeout(() => {
        this.modal = true
        this.$nextTick(() => {
          this.overflowControllers = responsiveTables(this.$el)
          responsiveEmbed(this.$el)
        })
        localStorage.setItem(storageKey, new Date().toString())
      }, this.container.timeDelay * 1000 || 0)
    }
  },
  destroyed() {
    this.overflowControllers.map(controller => controller.destroy())
  },
}
</script>
