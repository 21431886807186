<template>
  <section class="cwp-iframe">
    <div class="grid-container">
      <h3 v-if="container.heading" class="cwp-iframe__heading">{{ container.heading }}</h3>
      <div v-if="container.description" class="cwp-iframe__description" v-html="container.description"></div>
    </div>
    <iframe v-if="container.iframeUrl" class="cwp-iframe__iframe" :src="container.iframeUrl" title="runway iframe" />
  </section>
</template>

<script>
export default {
  name: 'CwpIframe',
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.cwp-iframe {
  &__iframe {
    border: 2px solid #e0e0e0;
    padding: 0;
    margin: auto;
    width: calc(100% - 64px);
    display: block;
    min-height: 90vh;

    @include media('>=large') {
      min-height: 770px;
    }

    @include media('>=xlarge') {
      min-width: 1104px;
      max-width: 80vw;
      margin: auto;
    }
  }
  &__heading {
    text-align: center;
  }
  &__description {
    text-align: center;
    margin-bottom: 64px;
  }
}
</style>
