<template>
  <section class="cwp-search-results">
    <div class="grid-container padding-y">
      <div class="cwp-search-results__grid">
        <div class="cwp-search-results__description">
          <h4 v-if="!loading && (!pagination.numRecords || !searchKeyword)" class="cwp-search-results__heading">
            Sorry, no results match your search criteria. Please enter another search term.
          </h4>
          <h4 v-else-if="pagination.numRecords === 1" class="cwp-search-results__heading">
            There is 1 matching result for "{{ searchKeyword }}"
          </h4>
          <h4 v-else-if="pagination.numRecords > 1" class="cwp-search-results__heading">
            There are {{ pagination.numRecords }} matching results for "{{ searchKeyword }}"
          </h4>
        </div>
        <div class="cwp-search-results__results">
          <div v-for="(result, i) in results" :key="i">
            <cwp-link :to="result.url" wrapper class="cwp-search-results__result-link">
              <h5 class="cwp-search-results__result-title" v-html="result.title"></h5>
            </cwp-link>
            <div
              class="cwp-search-results__result-content cwp-html-content"
              v-html="formatHighlights(result.searchHighlights.content)"
            />
          </div>
        </div>
        <div class="cwp-search-results__load-more">
          <transition name="fade">
            <cwp-link
              v-if="results.length && pageNumber < pagination.maxPageNo"
              :disabled="loading"
              button
              tag="button"
              @click="loadMore"
              >Load more results</cwp-link
            >
          </transition>
          <cwp-loader :loading="loading" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpLoader from '@/components/CwpLoader/CwpLoader.vue'
import Kentico from '@/api/kentico'
export default {
  name: 'CwpSearchResults',
  components: {
    CwpLink,
    CwpLoader,
  },
  data() {
    return {
      pageNumber: 1,
      results: [],
      pagination: {
        numRecords: 0,
        maxPageNo: 1,
      },
      loading: true,
    }
  },
  computed: {
    searchKeyword() {
      return this.$route.query.q
    },
  },
  watch: {
    searchKeyword() {
      this.results = []
      this.pageNumber = 1
      this.pagination = {
        numRecords: 0,
        maxPageNo: 1,
      }
      this.getSearchResults()
    },
  },
  mounted() {
    this.getSearchResults()
  },
  methods: {
    getSearchResults() {
      if (this.searchKeyword) {
        this.loading = true
        Kentico.getSearchResults(this.searchKeyword, this.pageNumber)
          .then(response => {
            if (response?.data) {
              this.results.push(...response.data.results)
              this.pagination = response.data.pagination
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },
    loadMore() {
      document.activeElement.blur()
      this.pageNumber += 1
      this.getSearchResults()
    },
    formatHighlights(contents) {
      // Each string usually ends with a full stop.
      return contents
        .map(content => {
          return content.substr(-1) === '.' ? content + '..' : content + '...'
        })
        .join(' ')
    },
  },
}
</script>

<style lang="scss">
.cwp-search-results {
  background-color: var(--background-color);

  &__grid {
    @include grid(12, 0 8px, 0 24px);
  }

  &__heading {
    margin: 48px 0 0;
    text-align: center;

    @include media('>=large') {
      margin: 104px 0 0;
    }
  }

  &__description {
    color: var(--text-color);
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: 2 / span 10;
    }

    @include media('>=large') {
      grid-column: 3 / span 8;
    }
  }

  &__results {
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: 2 / span 10;
    }

    @include media('>=large') {
      grid-column: 3 / span 8;
    }
  }

  &__result-link {
    margin: 32px 0 0 0;
  }

  &__result-title {
    color: var(--body-text-hyperlink-color);
    margin: 0;
  }

  &__result-content {
    em {
      font-weight: bold;
    }
  }

  &__load-more {
    margin-top: 32px;
    margin-bottom: 32px;
    grid-column: span 12;
    text-align: center;
  }
}
</style>
