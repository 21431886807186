<template>
  <span class="cell">
    <rv-card :card="card">
      <template #content>
        <div v-if="card.title" class="rv-card__title cwp-event-card__title">
          {{ card.title }}
        </div>
        <div v-if="card.eventDate" class="cwp-event-card__event-date">
          {{ formatDate(card.eventDate) }} {{ card.eventStartTime ? '@ ' + formatTime(card.eventStartTime) : '' }}
        </div>
        <div v-if="card.summaryText" class="rv-card__content" v-html="card.summaryText" />
        <div class="rv-card__cta">
          <cwp-link :to="card.url" button class="rv-card__button">Read more</cwp-link>
        </div>
      </template>
    </rv-card>
  </span>
</template>

<script>
import { RvCard } from '@revium/components'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import { formatDate, formatTime } from '@/functions/utils'
export default {
  name: 'CwpEventCard',
  components: {
    CwpLink,
    RvCard,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate,
    formatTime,
  },
}
</script>

<style lang="scss">
.cwp-event-card {
  &__title {
    color: var(--heading-font-color);
  }

  &__event-date {
    margin-bottom: 8px;
  }
}
</style>
